import { useEffect, useState } from "react";
import Loader from "../../components/loader";

const Game = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  function getQuery(search, field) {
    return new URLSearchParams(search).get(field);
  }
  const sessionKey = getQuery(window.location.search, "sessionKey");

  return (
    <section>
      {loader && <Loader />}
      {sessionKey ? (
        <iframe
          title="game"
          src={`https://app.droid.game?sessionKey=${sessionKey}`}
        ></iframe>
      ) : (
        <iframe title="game" src="https://app.droid.game"></iframe>
      )}
    </section>
  );
};

export default Game;
