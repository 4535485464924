import { useState } from "react";
import BG2 from "../../assets/images/inner-banner/bg2.jpg";

const Faq = () => {
  const [open, setOpen] = useState(-1);
  const data = [
    {
      title: "What is DROID?",
      content:
        "DROID is a social multiplier classic crash game where you compete with friends for fun. It’s a thrilling adventure through the galaxy, and best of all, it’s always free!",
    },
    {
      title: "How do I start playing?",
      content:
        "Simply sign up for an account, log in, and click on 'Play Now.' Follow the on-screen instructions to get started on your adventure.",
    },
    {
      title: "Is the game really free?",
      content:
        "Yes, DROID is completely free. There are no hidden charges or in-game purchases. It’s all about having fun without spending money.",
    },
    {
      title: "What is a classic crash game?",
      content:
        "A classic crash game is a type of game where players bet on how high a multiplier will go before it crashes. In DROID, you experience this thrill without any real money involved.",
    },
    {
      title:
        "How do I earn Solaris coins, and can I purchase them with real money?",
      content:
        "Solaris coins are an in-game virtual currency used only within DROID. You can earn Solaris coins by registering and verifying your email, inviting friends using a specially generated referral link, achieving top positions on the leaderboards, or through special promotions. Solaris coins have no value outside of the game and cannot be purchased with real money.",
    },
    {
      title: "Can I customize my droid?",
      content:
        "Yes, you can customize your droid with various skins and accessories available in the game. Show off your unique style as you explore the galaxy!",
    },
    {
      title: "How do I earn points and level up?",
      content:
        "You earn points by playing the game and successfully predicting the crash points. Accumulate points to level up and unlock new adventures and features.",
    },
    {
      title: "Is there a leaderboard?",
      content:
        "Yes, there is a leaderboard where you can see how you rank against other players. Compete for the top spot and earn bragging rights among your friends!",
    },
    {
      title: "What are the system requirements?",
      content:
        "The game runs on most modern web browsers. For the best experience, use the latest version of Chrome, Firefox, or Safari.",
    },
    {
      title: "How do I reset my password?",
      content:
        "If you need to reset your password, go to the 'Pilot Login' page and click on 'Forgot Password.' Follow the instructions to reset your password.",
    },
    {
      title: "What should I do if I encounter a bug?",
      content:
        "If you encounter a bug, please report it to us through the 'Mission Control' (Contact) section. Our team will work to resolve it as quickly as possible.",
    },
    {
      title: "Can users delete their accounts?",
      content:
        "Absolutely. If you wish to delete your account, please contact us through the 'Mission Control' (Contact) section, and we will assist you with the process.",
    },
  ];

  return (
    <>
      <section
        className="inner-banner bg_img"
        style={{ background: `url(${BG2}) top` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-xl-6 text-center">
              <h2 className="title text-white">Frequently Asked Questions</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-section padding-top padding-bottom overflow-hidden">
        <div className="container">
          <div className="faq-wrapper row justify-content-between">
            <div className="col-lg-6">
              {data.map((item, index) => (
                <div
                  key={index}
                  className={`faq-item ${index === open ? "open" : ""}`}
                  onClick={() => setOpen(index)}
                >
                  <div className="faq-item__title">
                    <h5 className="title">
                      {index + 1}. {item.title}
                    </h5>
                  </div>
                  <div
                    className="faq-item__content"
                    style={{ display: index === open ? "block" : "none" }}
                  >
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="shapes">
          <img
            src="assets/images/faq/shape.png"
            alt="faq"
            className="shape shape1"
          />
        </div>
      </section>
    </>
  );
};
export default Faq;
