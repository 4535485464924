import BG2 from "../../assets/images/inner-banner/bg2.jpg";
import Acoount from "../../assets/images/account/bg.jpg";
import Logo from "../../assets/images/load.gif";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import axios from "axios";

import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Registration = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    nickiname: "",
    password: "",
    passwordRepeat: "",
  });

  const query = useQuery();
  const invitationToken = query.get('invitationToken');

  const setData = (key, value) => {
    const copyOfUser = { ...user };
    copyOfUser[key] = value;
    setUser(copyOfUser);
  };

  const register = () => {
    if (!user.nickiname || !user.password || !user.passwordRepeat) {
      toast.error("Please fill all the fields");
      return false;
    }

    if (user.password !== user.passwordRepeat) {
      toast.error("Passwords do not much");
      return false;
    }

    axios
      .post("https://api-by.devcom.ge/api/user/register", {
        username: user.nickiname,
        password: user.password,
        invitationToken: invitationToken ? invitationToken : ''
      })
      .then(() => {
        toast.success("Successfully registered");
        navigate('/login')
        setUser({
          nickiname: "",
          password: "",
          passwordRepeat: "",
        });
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <section
        className="inner-banner bg_img"
        style={{ background: `url('${BG2}') top` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-xl-6 text-center">
              <h2 className="title text-white">Sign Up</h2>
            </div>
          </div>
        </div>
      </section>

      <Toaster />

      <section
        className="account-section overflow-hidden bg_img"
        style={{ background: `url('${Acoount}') top` }}
      >
        <div className="container">
          <div className="account__main__wrapper">
            <div className="account__form__wrapper sign-up">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <form className="account__form form row g-4">
                <div className="col-xl-6 col-md-6">
                  <div className="form-group">
                    <div htmlFor="email" className="input-pre-icon">
                      <i className="las la-envelope"></i>
                    </div>
                    <input
                      id="email"
                      type="email"
                      value={user.nickiname}
                      onChange={(e) => setData("nickiname", e.target.value)}
                      className="form--control form-control style--two"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="form-group">
                    <div htmlFor="pass" className="input-pre-icon">
                      <i className="las la-lock"></i>
                    </div>
                    <input
                      id="pass"
                      type="password"
                      value={user.password}
                      onChange={(e) => setData("password", e.target.value)}
                      className="form--control form-control style--two"
                      placeholder="Password"
                      required
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-md-6">
                  <div className="form-group">
                    <div htmlFor="pass" className="input-pre-icon">
                      <i className="las la-lock"></i>
                    </div>
                    <input
                      id="pass"
                      type="password"
                      value={user.passwordRepeat}
                      onChange={(e) =>
                        setData("passwordRepeat", e.target.value)
                      }
                      className="form--control form-control style--two"
                      placeholder="Confirm Password"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <button
                      className="cmn--btn active btn--lg button play-now w-100"
                      type="button"
                      onClick={() => register()}
                    >
                      Sign Up
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Registration;
