import LoaderGif from '../assets/images/mainloader.gif'

const Loader = () => {
  return (
    <div className="preloader">
      <div className="scene" id="scene">
        <img alt='' src={LoaderGif} />
      </div>
    </div>
  );
};

export default Loader