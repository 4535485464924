/* eslint-disable jsx-a11y/anchor-has-content */
import BG2 from "../../assets/images/inner-banner/bg2.jpg";

const Contact = () => {
  return (
    <>
      <section
        className="inner-banner bg_img"
        style={{ background: `url(${BG2}) top` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-xl-6 text-center">
              <h2 className="title text-white">Contact Page</h2>
            </div>
          </div>
        </div>
      </section>

      <div className="contact-section padding-top padding-bottom">
        <div className="container">
          <div className="contact-wrapper">
            <div className="row g-5 align-items-center">
              <div className="col-lg-5">
                <div className="contact-info-wrapper">
                  <h3 className="title mb-3 mb-lg-4">Contact Information</h3>
                  <ul className="contact-info-list m-0">
                    <li>
                      <span>
                        Need assistance or have questions?
                        <br />
                        First, check our
                        <b>Galactic Guide</b> for quick answers. If you still need
                        help, feel free to reach out to us at the following
                      </span>
                    </li>
                    <li>
                      <a href="tel:238754">
                        <i className="las la-phone-volume"></i>
                        <span>+47 8519-9415 1515</span>a
                      </a>
                    </li>
                    <li>
                      <a href="mailto:contact@droid.game">
                        <i className="las la-envelope"></i>
                        <span>contact@droid.game</span>
                      </a>
                    </li>
                  </ul>
                  <ul className="social-links mt-4">
                    <li>
                      <a href="#0">
                        <i className="lab la-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="lab la-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="lab la-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#0">
                        <i className="lab la-vimeo"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
