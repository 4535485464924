import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/home';
import Login from './pages/login/login';
import Faq from './pages/faq/faq';
import Game from './pages/game/gsme';
import Registration from './pages/registration/reg';
import Header from './components/header';
import Contact from './pages/contact/contact';
import Reset from './pages/reset';
import PasswordRecovery from './pages/reset/passwordrecovery'

const App = () => {
  return (
    <Router>
      <div>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/login" element={<Login />} />
          <Route path="/game" element={<Game />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/forgot" element={<Reset />} />
          <Route path="/passwordrecovery" element={<PasswordRecovery />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App