import { useEffect, useState } from "react";
import Bg from "../../assets/images/banner/bg.png";
import Loader from "../../components/loader";
import Banner from "../../assets/images/banner.png";
import { Link } from "react-router-dom";

const Home = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);
  return (
    <section
      className="banner-section bg_img overflow-hidden"
      style={{ background: `url(${Bg}) center` }}
    >
      {loader && <Loader />}
      <div className="container">
        <div className="banner-wrapper d-flex flex-wrap align-items-center">
          <div className="banner-thumb">
            <img src={Banner} alt="Droid" />
          </div>
          <div className="banner-content">
            <h1 className="banner-content__title">
              <span style={{ display: 'block' }} className="text--base">Droid Galaxy </span>
              Always Free, Always Fun!
            </h1>
            <p className="banner-content__subtitle">
              Join the ultimate social multiplier classic crash game where you
              compete with friends for pure fun. No money involved, just endless
              adventures and friendly competition in the galaxy!
            </p>
            <div className="button-wrapper">
              <Link
                to="game"
                className="cmn--btn active btn--lg button play-now"
              >
                <i className="las la-play"></i> Demo Game
              </Link>
              <Link to="register" className="cmn--btn btn--lg button sign-up">
                Play now!
              </Link>
            </div>
            {/* <img src={Card} alt="droid" className="shape1" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
