import { Link } from "react-router-dom";
import Logo from "../assets/images/load.gif";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [active, setActive] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      setActive(false)
    }, 10);
  }, [pathname]);

  return (
    <>
      <div className="overlay"></div>

      <div className="header">
        <div className="container">
          <div className="header-bottom">
            <div className="header-bottom-area align-items-center">
              <div className="logo">
                <Link to={"/"}>
                  <img src={Logo} alt="Droid" />
                </Link>
              </div>
              <ul className={`menu ${active ? "active" : ""}`}>
                <li>
                  <Link to={"/"}>Home Base</Link>
                </li>
                <li>
                  <Link to="faq">Galactic Guide</Link>
                </li>
                <li>
                  <Link to="login">Pilot Login</Link>
                </li>
                <li>
                  <Link to="register">Join the Crew</Link>
                </li>
                <li>
                  <Link to="contact">Mission Control</Link>
                </li>
                <button
                  onClick={() => setActive(false)}
                  className="btn-close btn-close-white d-lg-none"
                ></button>
              </ul>
              <div
                className="header-trigger-wrapper d-flex d-lg-none align-items-center"
                onClick={() => setActive(true)}
              >
                <div className="header-trigger">
                  <span></span>
                </div>
                <Link
                  to="login"
                  className="cmn--btn active btn--md d-none d-sm-block"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
